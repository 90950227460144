<template>
  <div>
    <video ref="myVideo"
            poster="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/629d55ef74c9bd0011bdaf63/16582806697835381168.png"
            src="@/assets/video/s7.mp4" autoplay loop="" controls oncontextmenu="return false" class="image_28"></video>
          
  </div>
</template>

<script>
export default {
  name: 'Vue3Video',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.image_28{
  width: 80vw;
}
</style>