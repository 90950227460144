<template>
  <div>
    <Wapheader></Wapheader> 
     <div class="flex-col page">
  <div class="flex-col">
    <div class="flex-col section_1">
      <div class="flex-col section_2">
        <div class="flex-col" style="visibility: hidden;">
          <div class="justify-between section_3" >
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562517119822.png"
              class="image"
            />
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/49b5ee4132c7bd4cbf401a48629ecc4b.png"
              class="image_1"
            />
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562519256218.png"
            class="image_2"
          />
        </div>
        <div class="flex-col items-center group_2">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588214747233989886.png"
            class="image_3"
          />
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16588214747239511901.png"
            class="image_4"
          />
        </div>
      </div>
      <div class="flex-col group_3">
        <span data-aos="fade-up" class="text">SINCE 1964</span>
        <div data-aos="fade-up" class="group_4">
          <span class="text_1">KYMCO打造一系列经典车型，组成摩托家族矩阵</span>
          <span class="text_2">成长为一家全球性的powersport集团，</span>
          <span class="text_3">业务遍及100多个国家。</span>
        </div>
      </div>
    </div>
    <div class="flex-col items-center">
      <img data-aos="fade-up" @click="openvideo"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562498630222.png"
        class="image_5"
      />
      <div data-aos="fade-up" class="group_6">
        <span class="text_4">光阳电动品牌秉持『最得我心』品牌理念</span>
        <span class="text_5">以独特出众的产品定位为多元化、差异化的</span>
        <span class="text_6">智能电动车市场需求提供富有</span>
        <span class="text_7">科技、浪漫、极具未来感的专业电动化产品</span>
        <span class="text_8">满足当下具有摩托情怀、注重品质和</span>
        <span class="text_9">体验的消费者对电动化出行方式的期待</span>
      </div>
    </div>
  </div>
  <div class="flex-col group_7">
    <div class="flex-col section_4">
      <div data-aos="fade-up" class="group_8">
        <span class="text_10">秉承超过半个世纪的浑厚造车功底，</span>
        <span class="text_11">在全新科技与人文精神的引领下，厚积薄发！</span>
      </div>
      <div  data-aos="fade-up" class="group_9">
        <span class="text_12">
          KYMCO光阳电动在不断的资金投入与严苛的研发下，技术储备已覆盖整车、动力电池、电机电控、智能中控、智能换电、云端物联网等多个领域。
        </span>
        <span class="text_13">并从电动技术、产品研发、实车检测、产线规划及制造供应链，已经形成完善的制造体系。</span>
      </div>
    </div>
    <div data-aos="fade-up" class="flex-col items-center section_5">
     
      <img 
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589023873646095797.png"
        class="image_10"
      />
      <div id="newwarp"></div>
      <div class="flex-col group_15">
         <div style="height: 115vh;"></div>
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562517643622.png"
          class="image_6"
        />
        <div class="group_10">
          <span class="text_14">ionex车能网，是光阳集团自主研发的</span>
          <span class="text_15">智能安全出行生态系统</span>
          <span class="text_16">。其核心特征是</span>
          <span class="text_17">硬件层、系统层以及生态层</span>
          <span class="text_18">均进行深度开发，是一个从硬件到芯片、云端再到应用的全生态架构。</span>
          <span class="text_19">ionex拥有强大的</span>
          <span class="text_20">自我迭代能力</span>
          <span class="text_21">，为全面开创两轮智</span>
          <span class="text_22">能出行的新体验打下坚实的基础，是全球电动车市场最佳智能化解决方案。</span>
        </div>
        <div class="flex-col items-center group_11">
          <span class="text_23">纯电专属，全面定义</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562512216832.png"
            class="image_7"
          />
        </div>
        <div class="flex-row group_12">
          <span class="text_24">了解更多</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562518770543.png"
            class="image_8"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<Wapfooter />
  </div>
</template>

<script>
import Wapheader from '../../components/wapheader/wapheader.vue';
import Wapfooter from '../../components/wapfooter/wapfooter.vue';
import videos from "@/components/video.vue"
import { TweenMax, TimelineMax } from 'gsap'
import Aos from "aos";
export default {
  name: 'Vue3New',
   components: { Wapheader, Wapfooter },
  data() {
    return {
      
    };
  },

  mounted() {
    Aos.init({
      offset: 120,
      duration: 500,
      delay: 500,
      once: true,
    })
     setTimeout(()=>{
          const scene2 = this.$scrollmagic.scene({
      triggerElement: '.section_5',
      // {0,0.5,1} - animations starts from {top,center,end} of window
      triggerHook: 0,
      offest:50,
      duration: 3000
    })
    .setTween('.group_15', {
      css: {
        yPercent: -50,
        opacity: 1,
        backdropFilter: "blur(50px)",

      }
    })
    scene2.setPin(".section_5");
    this.$scrollmagic.addScene(scene2)
    this.$scrollmagic.attachTo(this.$refs.scrollBox)
    },10)
  },

  methods: {
    openvideo() {
      this.$modal.open(videos)
    },
  },
};
</script>

<style scoped>
#newwarp {
  backdrop-filter: blur(50px);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;}
.page {
  padding-bottom: 2px;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group_7 {
  margin-top: 75px;
}
.section_1 {
  padding-bottom: 113px;
  background-color: #ffffff;
}
.section_4 {
  padding: 120px 65px 825px 79px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562513212385.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section_2 {
  padding-bottom: 316px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62d77d8b5fb3ce001150a042/16589001562468630021.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_3 {
  margin-top: 78px;
  align-self: center;
  width: 525px;
}
.image_5 {
  width: 620px;
  height: 349px;
}
.group_6 {
  margin-top: 52px;
}
.group_8 {
  margin-left: 5px;
  text-align: center;
}
.group_9 {
  margin-top: 54px;
}
.group_2 {
  margin-top: 248px;
}
.text {
  align-self: center;
  color: #000;
  font-size: 48px;
  
  line-height: 35px;
}
.group_4 {
  margin-top: 30px;
  line-height: 36px;
  text-align: center;
}
.text_4 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_5 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_6 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_7 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_8 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_9 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_10 {
  color: #000;
  font-size: 30px;
  
  line-height: 48px;
}
.text_11 {
  color: #000;
  font-size: 30px;
  
  line-height: 48px;
}
.text_12 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.text_13 {
  color: #000;
  font-size: 26px;
  
  line-height: 48px;
}
.section_3 {
  padding: 50px 52px;
  background-color: #ffffff;
}
.image_2 {
  width: 100vw;
  height: 2vw;
}
.image_3 {
  width: 282px;
  height: 153px;
}
.image_4 {
  margin-top: 49px;
  width: 284px;
  height: 49px;
}
.text_1 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_2 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_3 {
  color: #000;
  font-size: 24px;
  
  line-height: 36px;
}
.text_14 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_15 {
  color: #ffffff;
  font-size: 26px;
  
  font-weight: 700;
  line-height: 40px;
}
.text_16 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_17 {
  color: #ffffff;
  font-size: 26px;
  
  font-weight: 700;
  line-height: 40px;
}
.text_18 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_19 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_20 {
  color: #ffffff;
  font-size: 26px;
  
  font-weight: 700;
  line-height: 40px;
}
.text_21 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_22 {
  color: #ffffff;
  font-size: 26px;
  
  line-height: 40px;
}
.text_23 {
  color: #fffefe;
  font-size: 42px;
  
  font-weight: 500;
  line-height: 40px;
}
.text_24 {
  margin-left: 197px;
  color: #fffefe;
  font-size: 24px;
  
  font-weight: 500;
  line-height: 24px;
}
.image {
  margin: 5px 0;
  width: 321px;
  height: 30px;
}
.image_1 {
  margin-right: 5px;
  width: 48px;
  height: 40px;
}
.section_5 {
   height: 100vh;
  position: relative;
}
.image_10 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.image_6 {
  margin-right: 3px;
  width: 588px;
  height: 54px;
}
.group_10 {
  margin-top: 46px;
}
.group_10 span {
  display: block;
}
.group_15 {
  width: 100%;
  padding:0 100px;
  position: relative;
  height: 200vh;
  opacity: 0;
}
.group_11 {
  margin-top: 109px;
}
.group_12 {
  margin-top: 117px;
}
.image_7 {
  margin-top: 55px;
  width: 521px;
  height: 381px;
}
.image_8 {
  margin-left: 6px;
  margin-right: 200px;
  width: 91px;
  height: 23px;
}
</style>